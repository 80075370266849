.buttonDown {
    display: flex;
    justify-content: space-between;
  }
  
  #left {
    text-align: left;
  }
  
  #right {
    text-align: right;
  }
  
  .mediaButton {
    padding: 10px;
  }

  .category-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .category-item input[type="checkbox"] {
    margin-right: 5px;
  }

  .category-iteminput {
    display: inline-block;
    margin: 10px;
    color: #6966FF;
    padding: 5px;
    border-radius: 10px;
    background-color: #D4DCFF;
  }
  
  input[type='checkbox'] {
    all: initial;
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
    appearance: checkbox;
  }

  
  input[type='radio'] {
    all: initial;
    margin-right: 10px;
    -webkit-appearance: radio;
    -moz-appearance: radio;
    appearance: radio;
  }

  .italic {
    font-style: italic;
  }

  .smallCta.red.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .OptionSend {
    margin-top: 30px;
  }

  .sending-options {
    margin-top: 10px;
  }

  .sending-options-item {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .ant-steps-item-title {
    color:white !important;
  }

  .ant-steps-icon {
    color:white !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-finish.ant-steps-item-custom .ant-steps-item-icon >.ant-steps-icon {
    color:#6866ff !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color:none !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-wait .ant-steps-item-icon {
    border-color:white !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color:#1c1c2b !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-progress .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
    color:#6866ff !important;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-steps .ant-steps-item-process .ant-steps-item-icon {
    background-color:#6866ff !important;
    border-color:#6866ff !important;
  }

  :where(.css-yp8pcc).ant-steps .ant-steps-item-finish .ant-steps-item-icon {
    background-color:none !important;
  }


  .categoryContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; 
  }

  .vignetteMediaCategory {
    /* width: 49%; */
    border:1px solid;
    padding:15px;
    border-radius:10px;
    position:relative;
    margin-bottom:20px;
  }

  .i30 {
    width:100px;
  }

  #mediaCat h4 {
    margin-bottom:5px;
  }

  #mediaCat h6 {
    margin-bottom:10px;
  }

  #mediaCat .smallCta {
    letter-spacing: 0px;
    padding:5px;
  }
  
  .vignetteMediaCategory.selected {
    border: 2px solid #6866ff;
  }

  .vignetteMediaCategory .fa-check-circle {
    position: absolute;
    top: -10px;
    right: -10px;
    color: white;
    background-color: #6866ff;
    border-radius: 50%;
    padding: 2px;
  }
  

  .selectMediaDisplay {
    padding:10px 10px 20px 0px;
  }


#drawerModal td {
  color:#1c1c2b;
}

#drawerModal th {
  color:#1c1c2b;
}