#globalFooter {
    margin-top: 80px;
    padding: 80px 0 20px 0;
    background: linear-gradient(0deg, rgba(24,24,34,1) 0%, rgba(24,24,34,0) 100%);
}
#globalFooter .wrap > img {
    margin-right: 30px;
}
#globalFooter li a:not(:last-of-type) img {
    margin-right: 30px;
}
#globalFooter .wrap ul, 
#globalFooter img {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
}
#globalFooter .wrap ul li{
    display: inline-block;
    color: #FFF;
    /* cursor: pointer; */
    text-transform: uppercase;
}
#globalFooter .wrap ul li a {
    display: inline-block;
}
#globalFooter .wrap ul li:not(:last-of-type) {
    margin-right: 10px;
}

#globalFooter .wrap ul li:not(:last-of-type):after {
    margin-left: 30px;
    content: "";
    display: inline-block;
    height: 0px;
    width: 10px;
    background-color: #FFF;
    vertical-align: middle;
}

.smallText {
    padding-top: 30px;
    font-size: 18px;
    color: #9d9d9d;
    text-align: center;
    /* margin-left: 23%; */
}

.ReallysmallText {
    font-size: 14px;
}

.subFooter {
    font-size: 0.722rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.2;
}


@media screen and (max-width: 1024px) {
    #globalFooter {
        margin-top: 80px;
        padding: 20px 0 20px 0;
    }
    #globalFooter .wrap ul li:last-of-type {
        display: block;
        margin-top: 20px;
    }
    #globalFooter .wrap ul li:not(:last-of-type):after {
        display: none;
    }
    #globalFooter .wrap ul li:not(:last-of-type) {
        margin-right: 20px;
    }
    #globalFooter .wrap > img {
        margin-right: 0;
        margin-bottom: 20px;
    }
}