.mediaGrid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .mediaCard {
    background-color: #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 300px;
    text-align: center;
  }
  
  .mediaCard img {
    max-width: 100%;
    height: auto;
  }

  
#DetailsMedia h3 {
    padding-left: 0 !important;
    margin-bottom: 30px;
    color: #111117;
}

#DetailsMedia img {
    max-width: 80px;
    max-height: 80px;
    margin-bottom: 10px;
}

#DetailsMedia p {
    color: #111117;
}

.mediaContainer {
  display: flex;
  flex-wrap: wrap;
}

.mediaItem {
  flex: 1 0 30%; /* cela signifie que chaque élément aura une base de 20% de la largeur totale, mais peut s'étendre pour remplir l'espace disponible */
  min-width: 100px; /* vous pouvez définir une largeur minimale pour les éléments si vous le souhaitez */
  padding: 6px; /* un peu d'espacement pour rendre les choses jolies */
}


#filter {
  padding: 5px !important;
  max-width: 300px !important;
  margin-right: 30px;
  margin-bottom:30px;
  background-color:#D3DCFF;
  color:black;
}

#countryFilter {
  padding: 5px !important;
  max-width: 300px !important;
}