
.userDashboard nav {
    width: 15%;
    display: inline-block;
    vertical-align: top;
    position:absolute;
    margin-bottom: 20px;
  }
  
  .userDashboard nav li {
    position: relative;
  }
  .userDashboard nav li a {
    display: block;
    padding: 20px 0;
    font-weight: 600;
    font-size: 0.833rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(212,220,255,0.3);
  }
  .userDashboard nav li.disabled a {
    cursor: default;
    color: rgba(212,220,255,0.3);
  }
  .userDashboard nav li.disabled:after {
    content:"Comming Soon";
    position: absolute;
    display: inline-block;
    color: #fff;
    bottom: 4px;left: 27px;
    font-weight: 600;
    font-size: 0.633rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(212,220,255,0.1);
  }
  .userDashboard nav li:not(.disabled) a:hover {
    color: #6866ff;
    cursor: pointer;
  }
  .userDashboard nav li i {
    margin-right: 10px;
    font-size: 1rem;
    display: inline-block;
  }
  
.catNav {
  padding-top:30px;
  padding-bottom:15px;
  text-transform:uppercase;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color:#cecdff;
}

.active-link {
  color: #6866ff;
}

/* RESPONSIVE */

@media screen and (max-width: 1024px) {
    .userDashboard nav {
      width: 90%;
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
    }
    .userDashboard nav ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .userDashboard nav li {
      display: inline-block;
    }
    .userDashboard nav li a {
      display: inline-block;
      padding: 10px;
      border: none;
    }
    .userDashboard nav li i {
      margin-right: 0;
      font-size: 1.333rem;
    }
    .userDashboard nav li a > span {
      display: none;
    }
    .userDashboard nav li.disabled:after {
        display: none;
    }
  }