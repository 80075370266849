.description {
    width:100%;
    display:inline-grid;
    margin-bottom:10px;
}

.label {
    margin-bottom:10px;
}

.button {
    /* width:50%; */
    margin-bottom:10px;
    display:inline-grid;
}

.ml30 {
    margin-top: 30;
}

.vignette {
    background-color:#6866FF;
    padding:20px;
    border-radius:10px;
    min-height:200px;
    width:40%;
    text-align:center;
    align-items:center;
}

.vignette h5 {
    font-weight:bold;
    text-transform:uppercase;
    margin-bottom:10px;
}

.iconVign {
    font-size:35px;
}