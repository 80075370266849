#DashboardAdminTalents {
  overflow-y: auto;
  overflow-x: hidden;
}

#DashboardAdminTalents header {
  margin-bottom: 40px;
}

#DashboardAdminTalents header h1,
#DashboardAdminTalents header button {
  display: inline-block;
  vertical-align: middle;
}
#DashboardAdminTalents header h1{
  margin-right: 30px;
  margin-bottom: 0;
}

.articleWrap {
}

article.adminTalent {
  display: inline-block;
  vertical-align: top;
  width: 23%;
  min-width: 220px;
  text-align: center;
  background: #fff;
  color: #101012;
  border-radius: 8px;
  padding: 30px 1%;
  margin-right: 2.5%;
  margin-bottom: 30px;
}

article.adminTalent:nth-of-type(4n+4) {
  margin-right: 0;
}

article.adminTalent .picture {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  display: inline-block;
  background-color: #101012;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: contain;
  background-size: 60%;
  margin-bottom: 20px;
  filter: grayscale(1);
  }

article.adminTalent h3 {
  font-size: 2rem;
  margin-bottom: 10px;
  font-weight: bold;
}
article.adminTalent .name {
  font-weight: bold;
  margin-bottom: 10px;
}
article.adminTalent .id {
  font-size: 12px;
  font-weight: bold;
  word-break: break-all;
  max-width: 80%;
  margin: 0 auto;
}
