.RoadMapContainer {
    position: relative;
}

.RoadMapContainer .carousel-row {
    display: flex;
    align-items: stretch;
    cursor: grab;
}

article.rm-element:before {
    content: "";
    position: absolute;
    top: 100px;left: 0;
    z-index: -1;
    pointer-events: none;
    width: 100%;
    height: 2px;
    background-image: linear-gradient(to right, rgba(255,255,255,0.4) 40%, rgba(255, 255, 255, 0) 20%);
    background-position: top;
    background-size: 14px 3px;
    background-repeat: repeat-x;
}
article.rm-element.isDone:before {
    background-image: linear-gradient(to right, #dd1754 40%, rgba(255, 255, 255, 0) 20%);
}
article.rm-element {
    display: inline-block;
    position: relative;
}

article.rm-element .contentWrap {
    background-color: #0e0e13;
    padding: 30px 40px;
    border-radius: 10px;
    width: 88%;
    height: 100%;
    margin: 0 auto;
}


article.rm-element.isDone .contentWrap {
    background-color: #dd1754;
}