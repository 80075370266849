/* LOAD GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@500;700;800;900&display=swap');

/* LOAD FONT AWESOME */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");



/* LOAD GOOGLE FONTS */
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700&family=Exo+2:ital,wght@0,100;0,300;0,400;0,600;1,100;1,300;1,400;1,600&display=swap');

/* LOAD FONT AWESOME */
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css");



/* html, body, #root, #root > div, .globalContainer {
  min-height: 100vh;
  height: 100%;
} */

body {
  font-family: 'Exo 2', sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.6;
  background-color: #111117;
  color: #d4dcff;
  overflow-x: hidden;
  cursor: default;
}



/* 
TITLES STYLES
 */

h1, h2 {
  font-family: 'Dosis', sans-serif;
  font-weight: 500;
  line-height: 1.1;
  /* text-transform: uppercase; */
}

h1 {
  font-size: 5rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  position: relative;
  padding-left: 50px;
}

/* h2:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  border: 3px solid #6966FF;
  height: 20px;
  width: 20px;
  transform: scaleY(0.8) rotate(45deg) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0;
} */

h3 {
  font-family: 'Exo 2', sans-serif;
  font-size: 2rem;
  margin-bottom: 15px;
  line-height: 1;
  font-weight: 600;
}

h4 {
  line-height: 1;
  font-size: 1.5rem;
  font-weight: 600;
}

a, button {
  cursor: pointer;
}



/* 
GLOBAL CONTAINERS
 */
.wrap {
  max-width: 70%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

#HowItWorks, #infoWhatIsIt, #Price {
  background: url('/./public/grid.png') center center;
  background-size: 80%;
}

.globalContainer {
  background-color: #111117;
  color: #d4dcff;
}

.smallWrap {
  max-width: 1024px;
  padding: 0 30px;
}


.wrapConnect {
  margin: 0 auto;
  position: relative;
  z-index: 1;
  padding: 20px;
}



/* 
BUTTONS STYLES
 */
.ctaLink {
  font-weight: 800;
  display: inline-block;
  cursor: pointer;
}
.ctaLink:hover {
  text-decoration:underline;
}

.ctaLinkSmall {
  font-weight: 800;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  cursor: pointer;
}


.ctaMore {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #393953;
  background: #393953;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221,23,84, 0);
  margin-right: 30px;
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}

.soon {
  position: relative;
  overflow: hidden;
}

.soon::after {
  content: "Coming soon";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(28 28 43 / 62%);
  backdrop-filter: blur(5px);
  color: #ffffff;
  font-size: 2em;
  text-align: center;
  z-index: 1;
}

.soon * {
  position: relative;
  z-index: 0;
}

.ctaWhite {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #5D5A88;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #FFF;
  background: #FFF;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221,23,84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}
.ctaWhiteTall {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  text-align:center;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  width:100%;
  color: #5D5A88;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #FFF;
  background: #FFF;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221,23,84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}
.ctaGreenTall {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  text-align:center;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  width:100%;
  color: #FFF;
  border-radius: 10px 10px 10px 10px;
  background: #41ca41;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221,23,84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}

.newPastille {
  background: #FFEEF2;
  padding: 4px 5px;
  border-radius: 5px;
  color: #D63259;
  font-size: 13px;
  font-weight: 560;
  text-transform: uppercase;
  margin-left: 10px;
}

.soonPastille {
  background: #eefff0;
  padding: 4px 5px;
  border-radius: 5px;
  color: #32d67e;
  font-size: 13px;
  font-weight: 560;
  text-transform: uppercase;
  margin-left: 10px;
}

.iconGoogle {
  vertical-align: middle;
  margin-right: 10px;
}

.cta {
  display: inline-block;
  padding: 14px 40px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #FFF;
  border-radius: 10px 10px 10px 10px;
  cursor: pointer;
  background: #6966FF;
  background: #6966FF;
  vertical-align: middle;
  background-size: 200%;
  background-position: -120%;
  box-shadow: 0 0 20px rgba(221,23,84, 0);
  transition: background-position 0.2s ease-in 0.2s, box-shadow 0.2s linear 0s;
}
.cta i {
  margin-left: 10px;
}

.cta:hover {
  background-position: -200%;
  box-shadow: 0 0 30px rgba(117, 93, 255, 0.6);
  transition: background-position 0.2s ease-in, box-shadow 0.2s linear 0.1s;
}

.smallCta {
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  border:2px solid #d4dcff;
  background-color: #d4dcff;
  border-radius: 8px;
  color: #111117;
  transition: all 0.1s ease-out;
  transform: scale(1);
}
.smallCta:hover {
  transform: scale(1.02);
}

.smallCta.unFilled {
  background-color: transparent;
  color: #d4dcff;
}

.smallCta.dark {
  border:2px solid #111117;
  background-color: #111117;
  color: #d4dcff;
}

.smallCta.red {
  border:2px solid #6966FF;
  background-color: #6966FF;
  color: #d4dcff;
  transition: all 0.1s ease-out;
}

.smallCta.dark.unFilled {
  background-color: transparent;
  color: #111117;
}


/* 
  MARGIN & CENTERING CLASSES
*/

.tac p {
  margin-bottom: 50px;
}

.tac {
  text-align: center;
}
.tar {
  text-align: right;
}
.tal {
  text-align: left;
}

.vac {
  position: relative;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb30{
  margin-bottom: 30px;
}
.bold {
  font-weight: 600;
}
strong {
  font-weight: 600;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}




/* PREVENT USER SELECTION */
.preventSelect {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

/* DISABLED ELEMENTS */
.disabled {
  opacity: 0.3;
  cursor: default;
}


/*
  INPUTS & TEXTAREA
*/
input[type=email],
textarea,
input[type=number],
input[type=password],
input[type=text] {
  cursor:auto;
}

#modalcat input[type=text] {
  padding: 14px 0;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  background-color: rgba(0,0,0,0.02);
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  max-width:100%;
  width: 100%;
}

input[type=email], input[type=datetime-local], input[type=date], input[type=number], input[type=password], input[type=text] {
  padding: 14px 0;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  background-color: rgba(0,0,0,0.02);
  padding-left: 20px;
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  max-width: 600px;
  width: 100%;
}
textarea {
  padding: 14px 20px;
  border: 2px solid rgba(0,0,0,0.05);
  border-radius: 10px;
  background-color: rgba(212,220,255,0.1);
  font-size: 16px;
  max-width: 400px;
  width: 100%;
}

textarea[type=email]:focus, textarea[type=number]:focus, textarea[type=password]:focus, textarea[type=text]:focus, textarea:focus {
  background-color: rgba(212,220,255,0.1);
  border: 2px solid rgba(0,0,0,0.05);
}
input[type=datetime-local], input[type=date] {
  padding-right: 10px;
}

input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, input[type=text]:focus, textarea:focus {
  background-color: rgba(212,220,255,0.1);
  border-color:rgba(0,0,0,0.05);
}

/* input #firstname, #surname, #nickname, #email {
  max-width: 375px;
} */

.selectWrap {
  position: relative;
  display: inline-block;
}

select {
  padding: 14px 0;
  border: 2px solid #dbdee6;
  border-radius: 10px;
  background-color: #d4dcff;
  padding-left: 20px;
  font-size: 16px;
  text-align: left;
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  color: #111117;
}
select option {
  padding: 10px 20px;
  color: #111117;
  font-size: 16px;
  text-align: left;
  background-color: #d4dcff;
}
.selectWrap:after {
  content: "";
  display: inline-block;
  position: absolute;
  z-index: 2;
  right: 20px;
  top: 48%;
  transform: rotate(45deg) translateY(-50%);
  width: 8px;
  height: 8px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  color: #111117;
  pointer-events: none;
  opacity: 0.5;
}

.selectWrap:hover:after {
  opacity: 1;
}






/* =====
  Scrollbar
===== */

  /* Firefox */
  *:not(#root) {
    scrollbar-width: auto;
    scrollbar-color: #d4dcff;
  }

  /* Chrome, Edge, and Safari */
  *:not(#root)::-webkit-scrollbar {
    width: 14px;
  }

  *:not(#root)::-webkit-scrollbar-track {
    background: rgba(255,255,255,0.3);
    border-radius: 20px;
  }

  *:not(#root)::-webkit-scrollbar-thumb {
    background-color: #d4dcff;
    border-radius: 20px;
  }


    /* Firefox */
    body{
      scrollbar-width: auto !important;
      scrollbar-color: #d4dcff !important;
    }
  
    /* Chrome, Edge, and Safari */
    body::-webkit-scrollbar {
      width: 14px !important;
    }
  
    body::-webkit-scrollbar-track {
      background: rgba(255,255,255,0.1) !important;
      border-radius: 0 !important;
    }
  
    body::-webkit-scrollbar-thumb {
      background-color: #d4dcff !important;
      border-radius: 20px !important;
    }









/* 1024px RESPONSIVE */


@media screen and (max-width: 1024px) {
  body {
    font-size: 16px;
  }
  h1 {
    font-size: 3.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  .tac h2 {
    text-align: left;
  }
}