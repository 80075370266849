nav#globalHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
  z-index: 97;
  backdrop-filter: blur(0);
  transition: all 0.3s ease-out;
}

nav#globalHeader.shrink {
  backdrop-filter: blur(10px);
  background-color: rgba(255,255,255,0.02);
}

nav#globalHeader a {
  display: inline-block;
  padding: 10px;
  text-shadow: 0 0px 3px rgb(0 0 0 / 40%);
}
nav#globalHeader a.logo {
  padding-left: 0;
}

nav#globalHeader a:hover {
  color: #FFFF;
}

.buttonHeader {
  padding: 100px;
}

nav#globalHeader .navWrap {
  width: 90%;
  margin: 0 auto;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
nav#globalHeader ul li {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}

nav#globalHeader ul li.social.first:before {
  content:"";
  display: inline-block;
  vertical-align: middle;
  height: 30px;
  width: 1px;
  margin-right: 30px;
  background: rgba(255,255,255,0.4);
}

nav#globalHeader ul li.social a {
  padding: 10px 5px;
}
nav#globalHeader ul li.social a span {
  display: none;
}
nav#globalHeader ul li.social:not(:last-of-type) {
  margin-right: 10px;
}

nav#globalHeader ul li a i {
  margin-right: 10px;
}

nav#globalHeader ul li:not(:last-of-type) {
  margin-right: 20px;
}

nav#globalHeader .logoimg {
  max-height: 50px;
  display: inline-block;
}

nav#globalHeader .logoimg .cls-1{
  fill:#fff;
}
nav#globalHeader .logoimg .cls-2{
  fill:#e01462;
}

.avatar {
  border-radius: 20px;
  width: 30px;
  vertical-align: middle;
  margin-right: 10px;
}












nav#globalHeader .mobileMenu {
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: #de0068;
  border-radius: 50%;
  position: absolute;
  z-index: 3;
  top: 18px;right: 20px;
  cursor: pointer;
  display: none;
}

nav#globalHeader .mobileMenu span {
  display: inline-block;
  background: #fff;
  width: 40%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  z-index: 3;
  top: 0;left: 0;right: 0;bottom: 0;
  margin: auto;
}

nav#globalHeader .mobileMenu span:after,
nav#globalHeader .mobileMenu span:before {
  content:"";
  display: inline-block;
  background: #fff;
  width: 100%;
  height: 3px;
  border-radius: 50px;
  position: absolute;
  left: 0;
  -webkit-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
}
nav#globalHeader .mobileMenu span:before {
  top: -8px;
}
nav#globalHeader .mobileMenu span:after {
  top: 8px;
}






/* OPEN & CLOSE STATES */


/* NAV CLOSED */
@media screen and (max-width: 1024px) {
  /* DISPLAY MOBILE NAV BUTTON */
  nav#globalHeader .mobileMenu {
    display: none;
  }
  /* PREPARE NAV MENU ELEMENTS FOR MOBILE VIEW */
  nav#globalHeader a.logo {
    position: fixed;
    left: 50%;
    top: 26px;
    transform: translateX(-50%);
    z-index: 3;
  }
  nav#globalHeader .navWrap {
    width: 100%;
    top: 0;
    transform: none;
    display: block;
    height: 100%; 
    width: 100%;
    webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.02);
  }
  nav#globalHeader .navWrap:before {
    content:"";
    position: fixed;
    z-index: 1;
    background-color: rgb(17 17 23 / 99.5%);
    position: fixed;
    height: 100vh;
    width: 100vw;
    transform: translateY(-100%);
    opacity: 0;
    transition: opacity 0.2s ease-out;
  }
  nav#globalHeader ul {
    position: fixed;
    z-index: 2;
    top: 100px;left: 0;
    width: 100%;
    transform: scale(0);
    opacity: 0;
    -webkit-transition: opacity 0.2s ease-in-out;
    -o-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  nav#globalHeader ul li {
    display: block;
    margin-right: 0;
    margin-left: 0;
    padding: 10px 20px;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 2px solid rgba(212, 220, 255, 0.1);
    transform: translateY(10px);
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  nav#globalHeader ul li.social.first:before {
    display: none;
  }
  nav#globalHeader ul li.social a span {
    display: inline;
  }
}

/* NAV OPEN STYLES */

nav#globalHeader.navOpen .navWrap:before {
  transform: translateY(0);
  opacity: 1;
}

nav#globalHeader.navOpen ul {
  opacity: 1;
  transform: scale(1);
}

nav#globalHeader.navOpen ul li {
  transform: translateY(0);
  opacity: 1;
}

nav#globalHeader.navOpen .mobileMenu span {
  background: transparent;
}
nav#globalHeader.navOpen .mobileMenu span:before,
nav#globalHeader.navOpen .mobileMenu span:after {
  top: 0;
}
nav#globalHeader.navOpen .mobileMenu span:before {
  transform: rotate(45deg);
}
nav#globalHeader.navOpen .mobileMenu span:after {
  transform: rotate(-45deg);
}
