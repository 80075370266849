main.AdminDashboard {
  position: relative;
  height: 100vh;
  width: calc(100% - 280px);
  vertical-align: top;
  display: inline-block;
}

main.AdminDashboard h1 {
  font-size: 2.5rem;
  font-weight: 500;
}

main.AdminDashboard .results {
  margin-bottom: 20px;
}

main.AdminDashboard .wrap {
  top: 0;
  transform: none;
  height: calc(100% - 80px);
  margin-top: 80px;
  max-width: 90%;
}


#AdminDashboard ul li {
  display: inline-block;
  text-align: center;
}
#AdminDashboard ul li a {
  display: inline-block;
  padding: 30px 40px;
  min-width: 200px;
  border-radius: 6px;
  border:1px solid rgba(255,255,255,0.1);
}
#AdminDashboard ul li:not(:last-of-type) {
  margin-right: 30px;
}
#AdminDashboard ul li i {
  font-size: 2rem;
  margin-bottom: 20px;
}
