table{
  width:100%;
  table-layout: fixed;
}
.tbl-header{
  background-color: rgba(255,255,255,0.3);
 }
.tbl-content{
  height:300px;
  overflow-x:auto;
  margin-top: 0px;
  border: 1px solid rgba(255,255,255,0.3);
}

  #modalcat td {
    color:#000000;
  }

  #modalcat th {
    color:#000000;
  }

th{
  padding: 10px 0;
  text-align: left;
  font-weight: 500;
  font-size: 15px;
  color: #fff;
  text-transform: uppercase;
}
td{
  padding: 15px 0;
  text-align: left;
  vertical-align:middle;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
  border-bottom: solid 1px rgba(255,255,255,0.1);
}
td.first:first-child {
  width: 70px; /* Adjust the width percentage as needed */
}
th.first:first-child {
  width: 70px; /* Adjust the width percentage as needed */
}

td.first:nth-child(2) {
  width: 40%; /* Ajustez la largeur en pixels ou en pourcentage selon vos besoins */
}
th.first:nth-child(2) {
  width: 40%; /* Ajustez la largeur en pixels ou en pourcentage selon vos besoins */
}
td.first:nth-child(3) {
  width: 30%; /* Ajustez la largeur en pixels ou en pourcentage selon vos besoins */
}
th.first:nth-child(3) {
  width: 30%; /* Ajustez la largeur en pixels ou en pourcentage selon vos besoins */
}

#MediaTableCat th:nth-child(2),
#MediaTableCat td:nth-child(2) {
  width: 60%;
}

#MediaTableCat th:nth-child(1),
#MediaTableCat td:nth-child(1) {
  width: 15%; /* Ajustez la largeur comme vous le souhaitez */
}

#MediaTableCat th:nth-child(3),
#MediaTableCat td:nth-child(3) {
  width: 15%; /* Ajustez la largeur comme vous le souhaitez */
}

#MediaTableCat th:nth-child(4),
#MediaTableCat td:nth-child(4) {
  width: 5%; /* Ajustez la largeur comme vous le souhaitez */
}



/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}
::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

#drawerModal td {
  color:#1c1c2b;
}

#drawerModal th {
  color:#1c1c2b;
}

#drawerModal th:nth-child(2),
#drawerModal td:nth-child(2) {
  width: 60%;
}

#drawerModal th:nth-child(1),
#drawerModal td:nth-child(1) {
  width: 15%; /* Ajustez la largeur comme vous le souhaitez */
}

#drawerModal th:nth-child(3),
#drawerModal td:nth-child(3) {
  width: 15%; /* Ajustez la largeur comme vous le souhaitez */
}