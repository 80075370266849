#aboutMain {
    height: 60vh;
    min-height: 600px;
    position: relative;
  }
  
  
  #aboutMain h1,
  #aboutMain p {
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;
  }




  /* 
#aboutMain BACKGROUND GLITCH
 */
#aboutMain .mainBg {
    position: absolute;
    z-index: 0;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
  }
  #aboutMain .mainBg div {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 1;
    background: url('/./public/patternBg.jpg') no-repeat;
    background-size: cover;
    background-position: center bottom;
  }
  
  
  #aboutMain .mainBg div:nth-child(n+2) {
    opacity: 0;
    animation-duration: 4s;
    animation-delay: 0s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
  #aboutMain .mainBg div:nth-child(2) {
    background-color: none;
    background-blend-mode: none;
    animation-name: glitch-anim-2;
  }
  #aboutMain .mainBg div:nth-child(2) {
    background-color: none;
    background-blend-mode: none;
    animation-name: glitch-anim-3;
  }
  #aboutMain .mainBg div:nth-child(4) {
    background-color: none;
    background-blend-mode: none;
    animation-name: glitch-anim-4;
  }
  #aboutMain .mainBg div:nth-child(5) {
    background-color: overlay;
    background-blend-mode: overlay;
    animation-name: glitch-anim-flash;
  }
  


  #aboutMain h1 {
    position: relative;
  }

  #aboutMain h1:before {
    content:"";
    background: url('/./public/logo-text.png') no-repeat center center;
    background-size: contain;
    display: block;
    height: 20px;
    opacity: 0.2;
  }


  /* GLITCH H1 */
  #aboutMain h1 span:first-of-type, #aboutMain h1 span:last-of-type {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  #aboutMain h1 span:first-of-type {
    left: 2px;
    text-shadow: -1px 0 #00ffea;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim 2s linear infinite alternate-reverse;
  }
  #aboutMain h1 span:last-of-type {
    left: -2px;
    text-shadow: -1px 0 #fe3a7f;
    clip: rect(85px, 550px, 140px, 0);
    animation: glitch-anim 2s linear infinite alternate-reverse;
  }
  








/*
    GLITCH ANIMATIONS
*/

@keyframes glitch-anim-flash {
    0%, 5% {
      opacity: 0.2;
      transform: translate3d(10px, 100vh, 0);
    }
    5.5%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes glitch-anim {
    0% {
      clip: rect(100px, 9999px, 11px, 0);
    }
    5% {
      clip: rect(79px, 9999px, 36px, 0);
    }
    10% {
      clip: rect(119px, 9999px, 2px, 0);
    }
    15% {
      clip: rect(134px, 9999px, 40px, 0);
    }
    20% {
      clip: rect(62px, 9999px, 12px, 0);
    }
    25% {
      clip: rect(137px, 9999px, 84px, 0);
    }
    30% {
      clip: rect(88px, 9999px, 105px, 0);
    }
    35% {
      clip: rect(146px, 9999px, 30px, 0);
    }
    40% {
      clip: rect(27px, 9999px, 39px, 0);
    }
    45% {
      clip: rect(122px, 9999px, 7px, 0);
    }
    50% {
      clip: rect(42px, 9999px, 33px, 0);
    }
    55% {
      clip: rect(88px, 9999px, 20px, 0);
    }
    60% {
      clip: rect(128px, 9999px, 100px, 0);
    }
    65% {
      clip: rect(114px, 9999px, 56px, 0);
    }
    70% {
      clip: rect(40px, 9999px, 24px, 0);
    }
    75% {
      clip: rect(64px, 9999px, 40px, 0);
    }
    80% {
      clip: rect(119px, 9999px, 27px, 0);
    }
    85% {
      clip: rect(148px, 9999px, 42px, 0);
    }
    90% {
      clip: rect(42px, 9999px, 140px, 0);
    }
    95% {
      clip: rect(87px, 9999px, 127px, 0);
    }
    100% {
      clip: rect(41px, 9999px, 39px, 0);
    }
  }
  @keyframes glitch-anim-2 {
    0% {
      opacity: 1;
      transform: translate3d(10px, 0, 0);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(10px, 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-3 {
    0% {
      opacity: 1;
      transform: translate3d(calc(-1 * 10px), 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(calc(-1 * 10px), 0, 0);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-4 {
    0% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
    }
    22%, 100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  
  /*
    END GLITCH ANIMATIONS
*/




/* SECTION H2 */
#Tokenomy h2,
#HowItWorks h2,
#WhitelistBenefits h2,
#roadMap h2,
#Team h2 {
  display: inline-block;
  margin-bottom: 50px;
}






/* TOKENOMY */

  #Tokenomy {
    margin-top: 100px;
  }
  
  
  #Tokenomy ul li {
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #Tokenomy ul li .content p,
  #Tokenomy ul li .content h3 {
    max-width: 400px;
    min-width: 300px;
  }
 

  /* #Tokenomy ul li:first-of-type:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 25%;left: 0;
    height: 150%;
    width: 100%;
    display: block;
    background: url('/./public/bgPatternLight.jpg') no-repeat;
    background-size: cover;
    background-position: center bottom;
  } */


  #Tokenomy ul li img {
    width: 50%;
    max-width: 600px;
  }
  
  #Tokenomy ul li:nth-of-type(2) .content {
    order: 1;
  }
  #Tokenomy ul li:nth-of-type(2) .interestPoint {
    order: 2;
  }
  #Tokenomy ul li:nth-of-type(2) img {
    order: 3;
  }
  
  #Tokenomy ul li:not(:last-of-type){
    margin-bottom: 50px;
  }
  #Tokenomy ul li i {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .interestPoint {
    display: inline-block;
    margin-left: -8%;
    margin-right: 2%;
    width: calc(22% + 40px + 18px);
    position: relative;
    z-index: 3;
  }
  .interestPoint.fromRight {
    margin-left: 2%;
    margin-right: -20%;
    width: calc(25% + 40px + 18px);
  }
  .interestPoint > span {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .interestPoint > span.bigDot {
    height: 40px;width: 40px;
    margin-right: -1px;
    background: rgb(255, 255, 255, 1);
    border-radius: 50%;
  }
  .interestPoint > span:nth-of-type(2) {
    width: calc(100% - 40px - 18px);
    height: 2px;
    background-image: linear-gradient(to right, rgba(255,255,255,1) 40%, rgba(255, 255, 255, 0) 20%);
    background-position: top;
    background-size: 14px 3px;
    background-repeat: repeat-x;
  }
  .interestPoint > span.smallDot {
    height: 18px;width: 18px;
    margin-left: -1px;
    background: rgb(255, 255, 255, 1);
    border-radius: 50%;
  }



.interestPoint > span.bigDot:before,
.interestPoint > span.bigDot:after {
    content: "";
    position: absolute;
    top: 0;left: 0;
    height: 100%;
    width: 100%;
    display: inline-block;
    border-radius: 50%;
    background: rgb(255, 255, 255, 1);
    transform:scale(1);
}

.interestPoint > span.bigDot:before {
    animation: animateDot 2s ease-out infinite;
}
.interestPoint > span.bigDot:after {
    animation: animateDot 2s ease-out infinite 1s;
}

@keyframes animateDot {
    0%{
        transform:scale(1);
        opacity: 1;
    }
    100%{
        transform:scale(2);
        opacity: 0;
    }
}


/* TEAM */

#Team {
  margin-top: 100px;
}
#Team h2 {
  margin-bottom: 0;
}

#Team blockquote {
    position: relative;
    padding: 80px 0;
    display: block;
    max-width: 600px;
    margin: 0 auto;
    font-size: 1.333rem;
    letter-spacing: 2px;
    text-transform: uppercase;
}
#Team blockquote i {
  content:'"';
  position: absolute;
  z-index: -1;
  font-size: 18rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.05;
}

#Team .wrap > ul{
  text-align: center;
}

/* #Team .wrap > ul li width = (100% - marge * li.length - 1) / (li.length) */
#Team .wrap > ul li {
  display: inline-block;
  vertical-align: top;
  width: calc((100% - (5% * 3)) / 4);
  min-width: 200px;
  text-align: left;
  margin-bottom: 30px;
}

#Team .wrap > ul li:not(:last-of-type) {
  margin-right: 5%;
}

#Team .wrap ul li a {
  font-weight: 600;
  font-size: 0.833rem;
  letter-spacing: 1px;
  text-transform: uppercase;
}
#Team .wrap ul li a span,
#Team .wrap ul li a i {
  display: inline-block;
  vertical-align: middle;
}
#Team .wrap ul li i {
  font-size: 1.333rem;
}


.coinCss3DWrap {
  perspective: 600px;
}
.coinCss3D {
  display: inline-block;
  position: relative;
  transform-style: preserve-3d;
  width: 191px;
  height: 184px;
  transform: rotateY(-25deg);
  border-radius: 50%;
  animation: coinAnim 2s cubic-bezier(0.9,-0.02, 0, 1.43) infinite alternate;
  box-shadow: 0px 20px 30px rgb(0 0 0 / 30%);
  pointer-events: none;
}
.coinCss3DWrap:after {
  content:"";
  display: block;
  width: calc(191px * 1.5);
  height: calc(184px * 1.5);
  background: url('/./public/coinlight.png') no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translateZ(5px) translate(-50%, -50%);
  z-index: 3;
  filter: blur(20px);
}


.coinCss3D img {
  display: inline-block;
  position: absolute;
  z-index: 1;
  top: 0;left: 0;
}
.coinCss3D span {
  content:"";
  position: absolute;
  top: 0;left: 0;
  z-index: 1;
  background-color: #080808;
  display: inline-block;
  height: 100%;width: 100%;
  border-radius: 50%;
  transform: translateZ(-1px) translateY(2px) translateX(6px);
  animation: coinAnimZ 2s cubic-bezier(0.9,-0.02, 0, 1.43) infinite alternate;
}

@keyframes coinAnim {
  0%{
    transform: rotateY(-25deg);
  }
  100%{
    transform: rotateY(25deg);
  }
}

@keyframes coinAnimZ {
  0%{
    transform: translateZ(-1px) translateY(2px) translateX(6px);
  }
  100%{
    transform: translateZ(-1px) translateY(2px) translateX(-6px);
  }
}





@media screen and (max-width: 1024px) {
  .interestPoint {
    display: none;
  }
  #Tokenomy ul li {
    display: block;
  }
  #Tokenomy ul li img {
    width: 100%;
  }
  #Team blockquote {
    padding: 40px 0;
  }
  #Team .wrap > ul li:not(:last-of-type) {
    margin-right: auto;
    margin-left: auto;
  }
  #Team .wrap > ul li {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgb(212 220 255 / 30%);
  }
  #Team .wrap > ul li img {
    width: 40%;
  }
  #Team .wrap > ul li .content {
    width: 55%;
  }
}