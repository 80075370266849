.AccountValidationMain {
    padding-top: 200px;
    position: relative;
    text-align: center;
    min-height: 100%;
}

.AccountValidationMain h1 {
    margin-bottom: 20px;
}

.AccountValidationMain p {
    margin-bottom: 20px;
}