.vignetteMedia {
    background-color:grey;
    min-height:200px;
    min-width:300px;
    border-radius:10px;
    padding:10px;
    text-align:center;
}

.mediaTable {
    margin-top: 30px;
}

#filter {
    padding: 5px !important;
    max-width: 300px !important;
    margin-right: 30px;
}

#countryFilter {
    padding: 5px !important;
    max-width: 300px !important;
}