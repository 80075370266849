#mainRegister {
    padding-top: 150px;
    position: relative;
}

#mainRegister:before {
    content:"";
    position: absolute;
    opacity: 0.0918;
    mix-blend-mode: hard-light;
    /* filter: grayscale(1); */
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 0;
    background: url('../../public/pexels-redrecords-2872418.jpg') no-repeat center center;
    background-size: cover;
}
#mainRegister:after {
    content: "";
    position: absolute;
    bottom: 0;left: 0;
    height: 50%;
    width: 100%;
    z-index: 1;
    background: rgb(17,17,23);
    background: linear-gradient(0deg, rgba(17,17,23,1) 0%, rgba(17,17,23,0) 100%);  
}

#mainRegister .wrap {
    position: relative;
    z-index: 2;
}

#mainRegister h1 {
    line-height: 1;
    margin-bottom: 40px;
}


#mainRegister h1 .redLabel {
    background-color: #6866FF;
    margin-left: 10px;
    display: inline-block;
    padding: 6px 20px 8px 20px;
    font-size: 0.8em;
    border-radius: 6px;
    /* box-shadow: 0 0 30px rgb(221 23 84 / 100%); */
    position: relative;
}

#mainRegister h1 .redLabel:before,
#mainRegister h1 .redLabel:after {
    content: "NOW";
    opacity: 0.8;
    position: absolute;
    top: 0;
    left: 0;
    padding: 6px 20px 8px 20px;
}
#mainRegister h1 .redLabel:before {
    z-index: 1;
    color: rgba(255,255,255,0.618);
}
#mainRegister h1 .redLabel:after {
    z-index: 2;
    color: rgba(255,255,255,0.618);
}


#mainRegister h1 .redLabel:before {
    animation: glitch 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
}
#mainRegister h1 .redLabel:after {
    animation: glitch 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
}

@keyframes glitch {
    from {
      transform: translate(0);
    }
    20% {
      transform: translate(-5px, 5px);
    }
    40% {
      transform: translate(-5px, -5px);
    }
    60% {
      transform: translate(5px, 5px);
    }
    80% {
      transform: translate(5px, -5px);
    }
    to {
      transform: translate(0);
    }
  }



#mainRegister .formWrap fieldset input {
  margin-bottom: 25px;
  background: transparent;
  border:none;
  border-radius: 0;
  color: #fff;
  padding: 10px 0;
  border-bottom: 1px solid rgba(255,255,255,0.4);
}
#mainRegister .formWrap fieldset input:focus {
  border-bottom: 1px solid rgba(255,255,255,1);
}
#mainRegister .formWrap fieldset input:last-of-type {
    margin-bottom: 0;
}

#mainRegister .errorInput {
    border-color: #DD1754;
    box-shadow: 0 0 20px #dd1752d2;
}
#mainRegister .logMsg {
  font-size: 0.889rem;
}




/* Change Autocomplete styles in Chrome*/
#mainRegister input:-webkit-autofill,
#mainRegister input:-webkit-autofill:hover, 
#mainRegister input:-webkit-autofill:focus,
#mainRegister textarea:-webkit-autofill,
#mainRegister textarea:-webkit-autofill:hover,
#mainRegister textarea:-webkit-autofill:focus,
#mainRegister select:-webkit-autofill,
#mainRegister select:-webkit-autofill:hover,
#mainRegister select:-webkit-autofill:focus {
  border: none;
  background: none;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px #111117 inset;
  border-bottom: 1px solid rgba(255,255,255,1);
}












.formWrap {
  text-align: center;
  margin-bottom: 40px;
}
.formWrap fieldset {
  display: inline-block;
  max-width: 600px;
  text-align: left;
  vertical-align: top;
}
.formWrap fieldset:first-of-type {
  margin-right: 60px;
}




@media screen and (max-width: 1024px) {
  .formWrap fieldset:first-of-type {
    margin-right: 0;
  }
  .formWrap fieldset {
    max-width: 100%;
    display: block;
  }
}