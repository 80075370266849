#forTalents-main {
    padding: 150px 0 100px 0;
    position: relative;
}

#contactForTalents {
    padding: 50px 0;
}


#faqForTalents {
    padding: 50px 0;
}


.faqWrap .isOpen {
    height: auto;
}

.faqWrap h3 {
    padding: 0 20px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
}

.faqWrap li {
    text-align: left;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    overflow: hidden;
    border: 1px solid rgba(212, 220, 255, 0.1);
    margin-bottom: 20px;
}
