.ChangePasswordMain {
    padding-top: 150px;
}

.ChangePasswordMain h1 {
    margin-bottom: 30px;
    font-size: 3rem;
}

.ChangePasswordMain .wrap {
    max-width: 1024px;
    width: 75%;
    background: #1C1C2B;
    border-radius: 10px;
    padding: 80px 0;
}

.ChangePasswordMain .wrap input {
    color: #fff;
    background-color: rgba(212,220,255,0.1);
    border-color: rgba(212,220,255,0);
}
.ChangePasswordMain .wrap input:focus {
    border-color: rgba(212,220,255,1);
    background-color: rgba(212,220,255,0.1);
}





/* RESPONSIVE */


@media screen and (max-width: 1024px) {

    .ChangePasswordMain .wrap {
        max-width: 90%;
        width: 90%;
        padding: 40px 20px;
    }

}