.accordion {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin-bottom: 10px;
    max-width:90%;
    border-top:1px solid #393953;
    min-width: 90%;
  }
  .accordion:last-child {
    border-bottom: 1px solid #393953;
  }
  

  .accordionDiv {
    text-align:center;
  }
  
  .accordion-question {
    cursor: pointer;
    font-weight:bold;
    padding: 15px;
    /* background-color: #f1f1f1; */
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .accordion-indicator {
    font-weight: bold;
    font-size: 25px;
  }
  
  .accordion-answer {
    padding: 15px;
    text-align:left;
    color:#c8cce1;
  }
  