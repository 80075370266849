#UserDashboard h1 {
    /* margin-bottom: 60px; */
}

#UserDashboard .flexSpaceBetween {
    align-items: flex-start;
    flex-wrap: wrap;
}

#UserDashboard .stepWrap,
#UserDashboard .twitterFeed {
    display: inline-block;
    vertical-align: top;
}

#UserDashboard .twitterFeed {
    display: inline-block;
    width: 40%;
    min-width: 300px;
}

#UserDashboard .stepWrap {
    width: 40%;
    min-width: 300px;
    margin-right: 15%;
}

#UserDashboard .step {
    position: relative;
    padding: 20px 0;
}

#UserDashboard .step:first-of-type h4 {
    margin-bottom: 10px ;
}
#UserDashboard .step:before {
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0;
    font-size: 4rem;
    line-height: 1;
    font-weight: 600;
    opacity: 0.1;
}
#UserDashboard .step:nth-of-type(1):before {
    content: "01";
}
#UserDashboard .step:nth-of-type(2):before {
    content: "02";  
}
#UserDashboard .step:nth-of-type(3):before {
    content: "03";
}
#UserDashboard .step:nth-of-type(4):before {
    content: "04";
}
#UserDashboard .step:not(:last-of-type) {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgba(212,220,255, 0.3);
}

#UserDashboard .discord i {
    font-size: 1.5rem;    
    margin-right: 10px;
}

#UserDashboard .social a {
    display: inline-block;
    font-weight: 600;
    font-size: 0.833rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    border-radius: 50px;
    margin-bottom: 10px;
}
#UserDashboard .social a:not(:last-of-type) {
    margin-right: 14px;
}
#UserDashboard .social a i {
    font-size: 0.833rem;
}

#UserDashboard .step.disabled{
    border-color: rgba(212,220,255, 1);
}
#UserDashboard .step.disabled:after {
    content: "Comming Soon";
    display: block;
    color: #fff;
    font-weight: 600;
    font-size: 0.833rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: rgba(255,255,255,0.3);
}



#UserDashboard .timeline-TweetList-tweet {
    margin-bottom: 30px;
    background-color: #ffffff;
    border-radius: 14px;
    padding: 10px;
}



#UserDashboard input {
    color: #fff;
    background-color: rgba(212,220,255,0.1);
    border-color: rgba(212,220,255,0);
  }
  #UserDashboard input:focus {
    border-color: rgba(212,220,255,1);
    background-color: rgba(212,220,255,0.1);
  }
  
  #UserDashboard .errorInput {
      border-color: #DD1754;
      box-shadow: 0 0 20px #dd1752d2;
  }
  #UserDashboard .logMsg {
    font-size: 0.889rem;
  }
  
  .userReferralCode {
    color: #dd1754;
  }

  :where(.css-dev-only-do-not-override-yp8pcc).ant-btn-primary {
    background-color:#6866ff !important;
  }




/* RESPONSIVE */


@media screen and (max-width: 1024px) {
    .userDashboard {
      padding-top: 100px;
    }
    .userDashboard .wrap {
      max-width: 100%;
    }
    .userDashboard main {
      width: 100%;
      margin: 0;
      border-radius: 0;
      min-width: 0;
      background-color: transparent;
    }
    #UserDashboard .flexSpaceBetween {
      display: block;
      text-align: center;
    }*
    #UserDashboard .stepWrap {
      margin-bottom: 20px;
    }
    #UserDashboard .stepWrap, #UserDashboard .twitterFeed {
      display: block;
      min-width: 0;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
    #UserDashboard .social a span {
        display: none;
    }
    #UserDashboard .social a {
        font-size: 1.5rem;
        height: 60px;
        width: 60px;
        line-height: 60px;
        text-align: center;
        display: inline-block;
        background-color: rgba(212, 220, 255, 0.05);
        margin-bottom: 0;
    }
    #UserDashboard .social a i {
        font-size: 1.5rem;
    }
  }

  .sent {
    background-color:#f1fbeb;
    color: #9cc980;
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }

  .pending {
    background-color: #f5ebfb;
    color: #c980b9;
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }

  .error {
    background-color: #fbebeb;
    color: #ff0057;
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }

  .draft {
    background-color:var(--sib-color_surface-sidebar-background,#f9fafc);
    color:var(--sib-color_surface-hover-background,#c0ccda);
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }

  .other {
    background-color:var(--sib-color_surface-sidebar-background,#f9fafc);
    color:var(--sib-color_surface-hover-background,#c0ccda);
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }

  .scheduled {
    background-color:var(--sib-color_surface-sidebar-background,#fbebeb);
    color:var(--sib-color_surface-hover-background,#7d3a3a);
    border-radius: 50%; /* Pour obtenir un cercle parfait */
    width: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    height: 50px; /* Assurez-vous que la largeur et la hauteur sont égales */
    display: flex; /* Pour centrer l'icône */
    align-items: center; /* Centrer l'icône verticalement */
    justify-content: center; /* Centrer l'icône horizontalement */
  }