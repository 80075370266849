#homeMain {
  height: 100vh;
  position: relative;
}

.productHuntButton {
  vertical-align: -webkit-baseline-middle;
  margin-right: 30px;
}

#homeMain h1,
#homeMain p {
  /* max-width: 860px; */
  justify-items: center;
}

#homeMain p {
  margin-bottom: 40px;
}

.notationRanking {
  color: #ead900;
}

.avatarRanking {
  margin-top:15px;
  border-radius:20px;
  max-width:30px;
  max-height:30px;
  width:30px;
  height:30px;
  vertical-align: bottom;
  margin-left: 20px;
  margin-right: 10px;
}

.avisDisplay {
  margin-top:30px;
  max-width:60%;
  font-size:14px;
  display:inline-table;
  background-color: rgba(255, 255, 255, 0.747);
  border-radius: 10px;
  padding: 20px;
  color: black;
}

.noNeedCard {
  color:#858587;
  margin-left:300px;
  margin-top:5px;
  font-size:14px;
}

.line {
  max-width: 20%;
  position: absolute;
  left: 20%;
  top:55%;
}

#Screen h5 {
  font-family: Inter,Helvetica Neue,system-ui,-apple-system;
  font-size: .875rem;
  font-weight: 700;
  letter-spacing: .2em;
  margin-bottom: 15px;
  text-transform: uppercase;
}

#Screen h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  position: relative;
  padding-left:0px;
}

#Screen {
  margin-top: 200px;
  position: relative;
  overflow: hidden;
}

#Screen .bgContainer {
  position: absolute;
  top: 200px;
  left: 0;
  z-index: -1;
  height: 1748px;
  width: 2398px;
  overflow: hidden;
}
#Screen .bgContainer img {
  width: 2398px;
  height: 1748px;
  max-width: 2398px;
}


#Screen .wrap ul {
  display: flex;
  flex-direction: column;
}

#Screen .wrap ul li {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 160px;
}

#Screen .wrap ul li .content {
  max-width: 60%;
  display: inline-block;
  padding: 15px;
  vertical-align: middle;
}

#Screen .wrap ul li img {
  max-width: 60%;
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
}

#Screen .wrap ul li:nth-child(1) img {
  background-image: radial-gradient(circle farthest-corner at 125% 100%,#fff 19%,hsla(0,0%,100%,0) 41%),linear-gradient(38deg,hsla(0,0%,100%,0) 78%,hsla(0,0%,100%,.69)),linear-gradient(45deg,#fff 10%,hsla(0,0%,100%,0) 22%),linear-gradient(0deg,#fff,hsla(0,0%,100%,0) 91%),radial-gradient(circle farthest-corner at 0 -50%,hsla(0,0%,100%,0) 15%,rgba(112,207,255,.59) 38%,hsla(0,0%,100%,0) 65%),repeating-linear-gradient(214deg,hsla(0,0%,100%,0),rgba(97,76,255,.3) 25%,hsla(0,0%,100%,0) 71%),repeating-linear-gradient(212deg,hsla(0,0%,100%,0) 51%,rgba(124,20,252,.23) 64%,hsla(0,0%,100%,0) 80%);
}

#Screen .wrap ul li:nth-child(2) img {
  background-image: radial-gradient(circle farthest-corner at 125% 100%,#fff 19%,hsla(0,0%,100%,0) 41%),linear-gradient(38deg,hsla(0,0%,100%,0) 78%,hsla(0,0%,100%,.69)),linear-gradient(45deg,#fff 10%,hsla(0,0%,100%,0) 22%),linear-gradient(0deg,#fff,hsla(0,0%,100%,0) 91%),radial-gradient(circle farthest-corner at 0 -50%,hsla(0,0%,100%,0) 15%,rgba(255,195,246,.4) 38%,hsla(0,0%,100%,0) 65%),repeating-linear-gradient(214deg,hsla(0,0%,100%,0),rgba(80,0,241,.3) 25%,hsla(0,0%,100%,0) 71%),repeating-linear-gradient(212deg,hsla(0,0%,100%,0) 51%,rgba(251,74,186,.334) 64%,hsla(0,0%,100%,0) 80%);
}

#Screen .wrap ul li:nth-child(3) img {
  background-image: radial-gradient(circle farthest-corner at 125% 100%,#fff 19%,hsla(0,0%,100%,0) 41%),linear-gradient(38deg,hsla(0,0%,100%,0) 78%,hsla(0,0%,100%,.69)),linear-gradient(45deg,#fff 10%,hsla(0,0%,100%,0) 22%),linear-gradient(0deg,#fff,hsla(0,0%,100%,0) 91%),radial-gradient(circle farthest-corner at 0 -50%,hsla(0,0%,100%,0) 15%,rgba(249,167,26,.4) 38%,hsla(0,0%,100%,0) 65%),repeating-linear-gradient(214deg,hsla(0,0%,100%,0),rgba(255,52,143,.3) 25%,hsla(0,0%,100%,0) 71%),repeating-linear-gradient(212deg,hsla(0,0%,100%,0) 51%,rgba(255,209,27,.2) 64%,hsla(0,0%,100%,0) 80%);
}


#Screen .wrap ul li h4 {
  max-width: 510px;
  font-size: 44px;
  font-weight: 570;
  letter-spacing: 0;
  line-height: 1.1;
  margin-bottom: 20px;
}

#Screen .wrap ul li p {
  max-width: 500px;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.8;
}


.titleClick {
  color: #6966FF;
}

.downArrow {
  max-width: 35px;
  position: relative;
  margin-top: -85px;
  margin-bottom: 30px;
}

.mediaLogo {
  position: absolute;
  text-align: center;
  width: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  opacity: 0.7;
  bottom: 40px;
  /* -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.02); */
  /* background-color: #24242b; */
  /* border-radius: 10px; */
}

.colorPrimary {
  color:#6866ff;
}

.accordionDiv {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.mediaLogo img {
  padding: 20px;
  display: inline-block;
  width: 130px;
  /* opacity: 0.6; */
  
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  /* margin-bottom: 30px; */
}

.mediaLogo img:not(:last-of-type) {
  margin-right: 5%;
}

.gameLogoList {
  position: absolute;
  text-align: center;
  width: 75%;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 40px;
}

.gameLogoList img {
  display: inline-block;
  width: 100px;
  mix-blend-mode: luminosity;
  margin-bottom: 30px;
  vertical-align: middle;
}
.gameLogoList img:not(:last-of-type) {
  margin-right: 10%;
}

.gameLogoList:last-of-type {
  z-index: 2;
}
.gameLogoList:last-of-type img {
  mix-blend-mode: normal;
  opacity: 0;
  transition: opacity 1s ease-out;
}
.gameLogoList:last-of-type img:hover {
  opacity: 1;
}





/* 
#homeMain BACKGROUND GLITCH
 */
#homeMain .mainBg {
  position: absolute;
  z-index: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}

#homeMain .mainBg:before {
  content: "";
  height: 50%;
  width: 100%;
  display: block;
  position: absolute;
  z-index: 2;
  bottom: 0;left: 0;
  background: linear-gradient(0deg, rgba(17,17,23,1) 0%, rgba(17,17,23,1) 30%, rgba(17,17,23,0) 100%);
}

#homeMain .mainBg div {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  z-index: 1;
  background: url('/./public/patternBg.jpg') no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}


#homeMain .mainBg div:nth-child(n+2) {
  opacity: 0;
  animation-duration: 4s;
  animation-delay: 0s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
#homeMain .mainBg div:nth-child(2) {
  background-color: none;
  background-blend-mode: none;
  animation-name: glitch-anim-2;
}
#homeMain .mainBg div:nth-child(2) {
  background-color: none;
  background-blend-mode: none;
  animation-name: glitch-anim-3;
}
#homeMain .mainBg div:nth-child(4) {
  background-color: none;
  background-blend-mode: none;
  animation-name: glitch-anim-4;
}
#homeMain .mainBg div:nth-child(5) {
  background-color: overlay;
  background-blend-mode: overlay;
  animation-name: glitch-anim-flash;
}


#homeMain h1 {
  position: relative;
  text-align: center;
}
#homeMain h1 span:first-of-type, #homeMain h1 span:last-of-type {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#homeMain h1 span:first-of-type {
  left: 2px;
  text-shadow: -1px 0 #00ffea;
  clip: rect(24px, 550px, 90px, 0);
  animation: glitch-anim 2s linear infinite alternate-reverse;
}
#homeMain h1 span:last-of-type {
  left: -2px;
  text-shadow: -1px 0 #fe3a7f;
  clip: rect(85px, 550px, 140px, 0);
  animation: glitch-anim 2s linear infinite alternate-reverse;
}




/*
    GLITCH ANIMATIONS
*/

@keyframes glitch-anim-flash {
  0%, 5% {
    opacity: 0.2;
    transform: translate3d(10px, 100vh, 0);
  }
  5.5%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes glitch-anim {
  0% {
    clip: rect(100px, 9999px, 11px, 0);
  }
  5% {
    clip: rect(79px, 9999px, 36px, 0);
  }
  10% {
    clip: rect(119px, 9999px, 2px, 0);
  }
  15% {
    clip: rect(134px, 9999px, 40px, 0);
  }
  20% {
    clip: rect(62px, 9999px, 12px, 0);
  }
  25% {
    clip: rect(137px, 9999px, 84px, 0);
  }
  30% {
    clip: rect(88px, 9999px, 105px, 0);
  }
  35% {
    clip: rect(146px, 9999px, 30px, 0);
  }
  40% {
    clip: rect(27px, 9999px, 39px, 0);
  }
  45% {
    clip: rect(122px, 9999px, 7px, 0);
  }
  50% {
    clip: rect(42px, 9999px, 33px, 0);
  }
  55% {
    clip: rect(88px, 9999px, 20px, 0);
  }
  60% {
    clip: rect(128px, 9999px, 100px, 0);
  }
  65% {
    clip: rect(114px, 9999px, 56px, 0);
  }
  70% {
    clip: rect(40px, 9999px, 24px, 0);
  }
  75% {
    clip: rect(64px, 9999px, 40px, 0);
  }
  80% {
    clip: rect(119px, 9999px, 27px, 0);
  }
  85% {
    clip: rect(148px, 9999px, 42px, 0);
  }
  90% {
    clip: rect(42px, 9999px, 140px, 0);
  }
  95% {
    clip: rect(87px, 9999px, 127px, 0);
  }
  100% {
    clip: rect(41px, 9999px, 39px, 0);
  }
}
@keyframes glitch-anim-2 {
  0% {
    opacity: 1;
    transform: translate3d(10px, 0, 0);
    -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }
  2% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }
  4% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }
  6% {
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }
  8% {
    -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }
  10% {
    -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }
  12% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }
  14% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }
  16% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }
  18% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(10px, 0, 0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}
@keyframes glitch-anim-3 {
  0% {
    opacity: 1;
    transform: translate3d(calc(-1 * 10px), 0, 0);
    -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }
  3% {
    -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }
  5% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }
  7% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  9% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }
  11% {
    -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }
  13% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  15% {
    -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }
  17% {
    -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }
  19% {
    -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }
  20% {
    -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(calc(-1 * 10px), 0, 0);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}
@keyframes glitch-anim-4 {
  0% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
    -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }
  1.5% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }
  2% {
    -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }
  2.5% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }
  3% {
    -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }
  5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }
  5.5% {
    -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }
  7% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }
  8% {
    -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }
  9% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }
  10.5% {
    -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }
  11% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }
  13% {
    -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }
  14% {
    -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }
  14.5% {
    -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }
  15% {
    -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }
  16% {
    -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }
  18% {
    -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }
  20% {
    -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
  21.9% {
    opacity: 1;
    transform: translate3d(0, calc(-1 * 5px), 0) scale3d(-1, -1, 1);
  }
  22%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
    -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    clip-path: polygon(0 0, 0 0, 0 0, 0 0);
  }
}

/*
  END GLITCH ANIMATIONS
*/







/* HOW IT WORKS */

#HowItWorks {
  margin-top: 100px;
  margin-bottom: 60px;
}


/* HOW IT WORKS */
  
#HowItWorks ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#HowItWorks img {
  width:20%;
  margin-left:20%;
  margin-bottom:20px;
}

#HowItWorks h2 {
  display:block !important;
  margin-bottom: 0px !important;
}

#HowItWorks ul li .content {
  width: 40%;
  position: relative;
}
#HowItWorks ul li .content p,
#HowItWorks ul li .content h3 {
  max-width: 300px;
}

#HowItWorks ul li img {
  width: 60%;
  max-width: 1000px;
}

#HowItWorks ul li:nth-of-type(2) .content {
  order: 1;
}
#HowItWorks ul li:nth-of-type(2) img {
  order: 2;
}

#HowItWorks ul li:not(:last-of-type){
  margin-bottom: 50px;
}
#HowItWorks ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}


#HowItWorks ul li:first-of-type .content:before {
  content:"";
  position: absolute;
  top: -50%;left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url('/./public/gamesgrid.png') repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}


#infoWhatIsIt {
  margin-top: 100px;
  margin-bottom: 180px;
}


/* infoWhatIsIt */
  
#infoWhatIsIt ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#infoWhatIsIt ul li .content {
  width: 40%;
  position: relative;
}
#infoWhatIsIt ul li .content p,
#infoWhatIsIt ul li .content h3 {
  max-width: 300px;
}

#infoWhatIsIt ul li img {
  width: 60%;
  max-width: 1000px;
}

#infoWhatIsIt ul li:nth-of-type(2) .content {
  order: 1;
}
#infoWhatIsIt ul li:nth-of-type(2) img {
  order: 2;
}

#infoWhatIsIt ul li:not(:last-of-type){
  margin-bottom: 50px;
}
#infoWhatIsIt ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}


#infoWhatIsIt ul li:first-of-type .content:before {
  content:"";
  position: absolute;
  top: -50%;left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url('/./public/gamesgrid.png') repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}


#Price {
  margin-top: 100px;
  margin-bottom: 60px;
}

#Price .cta {
  margin-top: 30px;
  margin-bottom: 30px;
}
#Price .ctaWhite {
  margin-top: 30px;
  margin-bottom: 30px;
}

#Price img {
  width:20%;
}

#Price h2 {
  display:block !important;
  margin-bottom: 0px !important;
}


#Price ul li:nth-of-type(2) .content {
  order: 1;
}
#Price ul li:nth-of-type(2) img {
  order: 2;
}


.vignetNumber {
  background-color: #393953;
  border-radius: 10px;
  max-width: 25%;
  margin: 30px;
  display: inline-block;
  transform: rotate(-1deg);
}

.vignetNumber h1 {
  background-color: #393953;
  border-radius: 10px;
  font-weight: bold;
  font-size: 30px;
}


.vignetNumber h4 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 80%;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5rem;
  font-weight: 400;
}


.vignetNumber2 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 28%;
  margin: 30px;
  display: inline-block;
  transform: rotate(3deg);
}

.vignetNumber2 h1 {
  background-color: #393953;
  border-radius: 10px;
  font-weight: bold;
  font-size: 30px;
}


.vignetNumber2 h4 {
  background-color: #393953;
  border-radius: 10px;
  max-width: 80%;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
  line-height: 1.5rem;
  font-weight: 400;
}

.curvedArrow {
  position: absolute;
  left: 65%;
  width: 33% !important;
}

.curvedArrowWay {
  position: absolute;
  left: 65%;
  bottom: 0px;
  width: 33% !important;
}

.mr5 {
  margin-right: 5px;
}

.yellow {
  color:#F79330;
}

.circle {
  position: relative;
  top: -50px;
  margin-left: 25% !important;
}

.sparkle {
  top: -70px;
  left: 16%;
  width: 5% !important;
  position: relative;
}

.itemPrice {
  background-color: #FFF;
  text-align:left;
  display: inline-block;
  color:#5D5A88;
  width:40%;
  margin: 10px;
  padding:20px 20px 20px 20px;
  border-radius:10px;
}

.itemPrice h3 {
  font-size: 45px;
  display: inline-block;
}

.itemPriceFeatured {
  background-color: #393953;
  text-align:left;
  display: inline-block;
  color:#FFF;
  width:40%;
  top: 20px;
  margin: 10px;
  padding:20px 20px 20px 20px;
  border-radius:10px;
}

.itemPrice hr {
  border: 1px solid #D4D2E3;
  margin-bottom: 40px;
}

.itemPriceFeatured hr {
  border: 1px solid #D4D2E3;
  margin-bottom: 40px;
}

.itemPriceFeatured h3 {
  font-size: 45px;
  display: inline-block;
}


.itemPriceCredits {
  background-color: #FFF;
  text-align:left;
  display: inline-block;
  color:#5D5A88;
  width:90%;
  margin: 10px;
  padding:20px 20px 20px 20px;
  border-radius:10px;
}

.itemPriceCredits h3 {
  font-size: 45px;
  display: inline-block;
}

.text {
  color:#5D5A88;
}

.itemPrice h6 {
  color:#5D5A88;
}

.itemPrice h3 {
  color:#5D5A88;
}

.itemPrice ul {
  /* line-height:0rem; */
}

.itemPrice li {
  text-align:left;
  /* margin-right:10px; */
}

.p30 {
  padding: 30px;
}

#NowGo {
  margin-top: 150px;
  margin-bottom: 60px;
}

#NowGo .tac {
  background-image: linear-gradient(to bottom right, #6966FF, #bcbbf886);
  border-radius: 10px;
  padding: 30px;
}

#NowGo .tac .ctaWhite {
  margin-top: 40px;
}

#NowGo .tac p {
  margin-bottom: 0px;
}


#NowGo ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#NowGo img {
  width:20%;
}

#NowGo h2 {
  display:block !important;
  margin-bottom: 0px !important;
}

#NowGo ul li .content {
  width: 40%;
  position: relative;
}
#NowGo ul li .content p,
#NowGo ul li .content h3 {
  max-width: 300px;
}

#NowGo ul li img {
  width: 60%;
  max-width: 1000px;
}

#NowGo ul li:nth-of-type(2) .content {
  order: 1;
}
#NowGo ul li:nth-of-type(2) img {
  order: 2;
}

#NowGo ul li:not(:last-of-type){
  margin-bottom: 50px;
}
#NowGo ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}


#NowGo ul li:first-of-type .content:before {
  content:"";
  position: absolute;
  top: -50%;left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url('/./public/gamesgrid.png') repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}

  
#FAQ {
  margin-top: 150px;
  margin-bottom: 60px;
}

#FAQ ul li {
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#FAQ img {
  width:20%;
}

#FAQ h2 {
  display:block !important;
  margin-bottom: 0px !important;
}

#FAQ ul li .content {
  width: 40%;
  position: relative;
}
#FAQ ul li .content p,
#FAQ ul li .content h3 {
  max-width: 300px;
}

#FAQ ul li img {
  width: 60%;
  max-width: 1000px;
}

#FAQ ul li:nth-of-type(2) .content {
  order: 1;
}
#FAQ ul li:nth-of-type(2) img {
  order: 2;
}

#FAQ ul li:not(:last-of-type){
  margin-bottom: 50px;
}
#FAQ ul li i {
  font-size: 3rem;
  margin-bottom: 20px;
}


#FAQ ul li:first-of-type .content:before {
  content:"";
  position: absolute;
  top: -50%;left: -50%;
  z-index: -1;
  height: 200%;
  width: 200%;
  background: url('/./public/gamesgrid.png') repeat center center;
  background-position: 84% 45%;
  background-size: 20%;
  background-attachment: fixed;
}


/* BENEFITS */

#WhitelistBenefits {
  margin-bottom: 100px;
}

#WhitelistBenefits .listWrap {
  background: rgba(0,0,0,0.1);
  padding: 80px 20px;
}

#WhitelistBenefits ul {
  display: inline-block;
  vertical-align: top;
  text-align: left;
}
#WhitelistBenefits ul:last-of-type {
  margin-left: 80px;
}
#WhitelistBenefits ul li {
  max-width: 600px;
  margin-bottom: 10px;
  padding: 20px 0;
  display: inline-block;
}

#WhitelistBenefits ul li i {
  margin-right: 10px;
  color: #dd1754;
}

#WhitelistBenefits ul li p {
  font-size: 0.833rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}




/* RESPONSIVE */

@media screen and (max-width: 1024px) {
  #homeMain {
    height: auto;
  }
  #homeMain .mainBg div {
    background-position-x: 75%;
    background-position-y: 50%;
  }
  #homeMain .wrap {
    top: 0;
    transform: none;
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .gameLogoList {
    display: none;
  }

  #phoneOFF {
    display:none;
  }

  #Screen {
    display:none;
  }

  .mediaLogo {
    position:inherit;
    text-align: center;
    width: 100%;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 40px;
  }

  .vignetNumber {
    background-color: #393953;
    border-radius: 10px;
    max-width: 100%!important;
    margin: 30px;
    display: inline-block;
    transform: rotate(0deg);
  }
  .vignetNumber2 {
    background-color: #393953;
    border-radius: 10px;
    max-width: 100%!important;
    margin: 30px;
    display: inline-block;
    transform: rotate(0deg);
  }

  #HowItWorks h2 {
    text-align:center;
    padding-left: 0px !important;
  }

  #HowItWorks {
    margin-top:100px;
  }

  .avisDisplay {
    display: none;
  }

  #NowGo h2 {
    text-align:center;
    padding-left: 0px !important;
    margin-top:15px;
  }

  #infoWhatIsIt h2 {
    text-align:center;
    padding-left: 0px !important;
  }

  #infoWhatIsIt {
    margin-top:100px;
    margin-bottom:0px;
  }

  .accordion {
    max-width: 95%;
  }

  .itemPrice {
    width: 100%;
  }

  .itemPriceFeatured {
    width: 100%;
  }

  #Price h2 {
    text-align:center;
    padding-left: 0px !important;
  }

  #FAQ {
    margin-top:0px;
  }

  #FAQ h2 {
    text-align:center;
    padding-left: 0px !important;
  }

  .noNeedCard {
    display:none;
  }

  #FAQ img {
    width: 50%;
    top: -70px;
  }

  .sparkle {
    top: -50px;
    left: 50%;
    width: 5% !important;
    position: relative;
  }

  #HowItWorks ul li {
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin-right: 0;
    margin-left: 0;
    overflow: hidden;
  }
  #HowItWorks ul li:first-of-type .content:before {
    background-size: 90%;
  }
  #HowItWorks ul li img {
    width: 100%;
  }
  #HowItWorks ul li .content {
    width: 100%;
  }
  #HowItWorks ul li .content p, #HowItWorks ul li .content h3 {
    max-width: inherit;
  }
  #HowItWorks ul li:nth-of-type(2) img {
    order: 1;
  }
  #HowItWorks ul li:nth-of-type(2) .content {
    order: 2;
  }

  #WhitelistBenefits {
    margin-bottom: 60px;
  }
  #WhitelistBenefits .listWrap {
    padding: 60px 40px;
  }
  #WhitelistBenefits .wrap {
    max-width: 100%;
  }
  #WhitelistBenefits ul {
    display: block;
  }
  #WhitelistBenefits ul:last-of-type {
    margin-left: 0;
  }

  #HowItWorks .wrap {
    max-width: 90%;
  }
  
}