.modalCookie {
    position: fixed;
    z-index: 90;
    bottom: 0;
    height:70px;
    left: 0;
    width: 100%;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(255,255,255,0.02);
}
.modalCookie p {
    display: inline-block;
    margin-right: 20px;
}

.modalCookie p,
.modalCookie a {
    /* vertical-align: middle; */
}

.modalCookie .smallCta {
    padding: 4px 14px;
}

.modalCookie .cookieContent {
    padding: 20px 0;
}


/* RESPONSIVE */

@media screen and (max-width: 1024px) {
    .modalCookie {
        position: fixed;
        z-index: 90;
        bottom: 0;
        height:100px;
        left: 0;
        width: 100%;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(255,255,255,0.02);
    }
    .modalCookie p {
        /* display: inline-block; */
        /* margin-right: 20px; */
    }
    
    .modalCookie p,
    .modalCookie a {
        /* vertical-align: middle; */
    }
    
    .modalCookie .smallCta {
        padding: 4px 14px;
    }
    
    .modalCookie .cookieContent {
        padding: 0;
    }
    .tac p {
        margin-bottom:5px !important;
    }
}