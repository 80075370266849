.green {
    color:rgb(63, 134, 0);
}

.ant-card-body {
    font-size: 20px;
    text-align:center;
    text-transform:uppercase;
}

.ant-card-body h4{
    font-size: 40px;
    font-weight:bold;
    margin-bottom:10px;
    text-align:center;
    text-transform:uppercase;
}

.ant-card-body h6{
    font-size: 14px;
    font-weight:500;
    margin-bottom:10px;
    text-align:center;
}

.text16 {
    font-size:16px;
}


.premium {
    position: relative;
    overflow: hidden;
}
  
.premium::after {
    content: "Premium only";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(28 28 43 / 62%);
    backdrop-filter: blur(5px);
    color: #ffffff;
    font-size: 2em;
    text-align: center;
    z-index: 1;
}

.premium * {
    position: relative;
    z-index: 0;
}