.editor {
    height: auto;
    min-height: 600px;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    background-color: #2F2F41;
}

.ql-container {
    min-height: 600px;
  }

#Editor p {
    margin-bottom: 30px;
    border-bottom: 1px solid #FFF;
    border-bottom-width: 30%;
}

.right {
    text-align: right;
}

#cke_PressPilot_Editor {
    min-height: 600px;
}

#cke_1_contents {
    min-height: 600px;
}

[id^="cke_"][id$="_contents"] {
    height: 600px !important;
  }

.cke_contents cke_reset {
    height:600px !important;
}

.cke_inner cke_reset {
    min-height: 600px !important;
}